import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import * as yup from "yup";

import { requiredString, requiredWhen } from "../../helpers/validators";
import { dataService } from "../../services/data.service";

export const TEST_ID_SPECIAL_BLOCK_INPUT = "SpecialBlockInput";
export const TEST_ID_SPECIAL_BLOCK_VALIDATION_ERROR = "SpecialBlockValidationError";
export const TEST_ID_SPECIAL_REQUEST_DIALOG = "SpecialRequestDialog";
export const TEST_ID_SPECIAL_REQUEST_DETAILS = "SpecialRequestDetails";
export const TEST_ID_SPECIAL_REQUEST_DETAILS_VALIDATION_ERROR =
  "SpecialRequestDetailsValidationError";
export const TEST_ID_SPECIAL_CUSTOM_REQUEST_DETAILS_CHECKBOX =
  "SpecialCustomRequestDetailsCheckbox";
export const TEST_ID_SPECIAL_CUSTOM_REQUEST_DETAILS_INPUT =
  "SpecialCustomRequestDetailsInput";
export const TEST_ID_SPECIAL_SUBMIT = "SpecialSubmit";
export const TEST_ID_SPECIAL_CANCEL = "SpecialCancel";

interface RequestSpecialDialogProps {
  internalCaseId: string;
  setShowRequestSpecialDialog: (x: boolean) => void;
}

const SpecialRequestSchema = yup
  .object({
    block: requiredString.matches(/^[A-Z][1-9]$/),
    presetDetails: requiredWhen("isCustomRequest", false),
    customDetails: requiredWhen("isCustomRequest", true),
    isCustomRequest: yup.boolean().required(),
  })
  .required();

type SpecialRequestForm = yup.InferType<typeof SpecialRequestSchema>;

const BlockTextField = (): React.JSX.Element => {
  const { register, setValue, formState } = useFormContext<SpecialRequestForm>();
  const hasBlockError = !!formState.errors.block;

  // Force input to uppercase
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setValue("block", value.toUpperCase());
  };

  return (
    <div className="field mb-4">
      <label className="label" htmlFor="block">
        Block
      </label>
      <div className="control">
        <input
          autoFocus
          type="text"
          id="block"
          placeholder="E.g. A1"
          style={{ maxWidth: 90 }}
          aria-invalid={hasBlockError ? "true" : "false"}
          className={classNames("input", { "is-danger": hasBlockError })}
          data-testid={TEST_ID_SPECIAL_BLOCK_INPUT}
          {...register("block", { onChange })}
        />
      </div>
      {hasBlockError && (
        <p
          className="mt-1 has-text-danger"
          data-testid={TEST_ID_SPECIAL_BLOCK_VALIDATION_ERROR}
        >
          Please enter a valid block
        </p>
      )}
    </div>
  );
};

const PresetDetailsRadioGroup = (): React.JSX.Element => {
  const { register, setValue } = useFormContext<SpecialRequestForm>();
  const presetDetailsOptions = [
    "p53",
    "Re-scan H&E",
    "Re-scan TFF3",
    "Re-spin",
    "ALOX15",
  ];

  // Clear custom details when selecting any preset option
  const onChange = (): void => {
    setValue("isCustomRequest", false);
    setValue("customDetails", "", { shouldValidate: true });
  };

  return (
    <div role="radiogroup" aria-labelledby="presetDetailsLabel" className="field">
      <label id="presetDetailsLabel" className="label">
        Request details
      </label>
      <div className="control">
        {presetDetailsOptions.map((option) => {
          return (
            <label className="radio ml-0 mt-1 mr-5" key={option}>
              <input
                type="radio"
                value={option}
                className="mr-2"
                data-testid={TEST_ID_SPECIAL_REQUEST_DETAILS + option}
                {...register("presetDetails", { onChange })}
              />
              {option}
            </label>
          );
        })}
      </div>
    </div>
  );
};

const CustomRequestCheckbox = (): React.JSX.Element => {
  const { register, setValue } = useFormContext<SpecialRequestForm>();

  // Reset all details when toggling preset vs. custom details checkbox
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    setValue("presetDetails", "");
    setValue("customDetails", "", { shouldValidate: !checked });
  };

  return (
    <div className="field mt-5">
      <label className="checkbox" htmlFor="isCustomRequest">
        <input
          type="checkbox"
          id="isCustomRequest"
          className="mr-2"
          data-testid={TEST_ID_SPECIAL_CUSTOM_REQUEST_DETAILS_CHECKBOX}
          {...register("isCustomRequest", { onChange })}
        />
        I would like to make a different request
      </label>
    </div>
  );
};

const CustomDetailsTextArea = (): React.JSX.Element | null => {
  const { register, formState, watch } = useFormContext<SpecialRequestForm>();
  const { isCustomRequest } = watch();
  const hasCustomDetailsError = !!formState.errors.customDetails;

  if (!isCustomRequest) return null;

  return (
    <div className="field mb-0">
      <label className="is-sr-only" htmlFor="customDetails">
        Custom request details
      </label>
      <div className="control">
        <textarea
          rows={1}
          maxLength={250}
          id="customDetails"
          placeholder="Write your request here"
          aria-invalid={hasCustomDetailsError ? "true" : "false"}
          className={classNames("textarea", { "is-danger": hasCustomDetailsError })}
          data-testid={TEST_ID_SPECIAL_CUSTOM_REQUEST_DETAILS_INPUT}
          {...register("customDetails")}
        ></textarea>
      </div>
    </div>
  );
};

const RequestDetailsValidationError = (): React.JSX.Element | null => {
  const { formState } = useFormContext<SpecialRequestForm>();
  const hasDetailsError =
    !!formState.errors.presetDetails || !!formState.errors.customDetails;

  if (!hasDetailsError) return null;

  return (
    <p
      className="mt-1 has-text-danger"
      data-testid={TEST_ID_SPECIAL_REQUEST_DETAILS_VALIDATION_ERROR}
    >
      Please include details about your request
    </p>
  );
};

const RequestSpecialDialog = ({
  internalCaseId,
  setShowRequestSpecialDialog,
}: RequestSpecialDialogProps): React.JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const methods = useForm<SpecialRequestForm>({
    mode: "onSubmit",
    defaultValues: {
      block: "",
      presetDetails: "",
      customDetails: "",
      isCustomRequest: false,
    },
    resolver: yupResolver(SpecialRequestSchema),
  });

  const onSubmit = async (form: SpecialRequestForm): Promise<void> => {
    setError("");
    setBusy(true);
    const { block, presetDetails, isCustomRequest, customDetails } = form;
    const details = isCustomRequest ? customDetails : presetDetails;
    const response = await dataService.createSpecial(internalCaseId, block, details);
    if (response.data) {
      enqueueSnackbar("Special requested successfully", { variant: "success" });
      handleClose();
    }
    if (response.error) {
      setBusy(false);
      setError(response.error.msg);
    }
  };

  const handleClose = () => !busy && setShowRequestSpecialDialog(false);

  return (
    <div className="modal is-active" data-testid={TEST_ID_SPECIAL_REQUEST_DIALOG}>
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <div className="box has-background-grey-lighter">
          <h3 className="title is-4">Request special</h3>
          <div className="content">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <BlockTextField />
                <PresetDetailsRadioGroup />
                <CustomRequestCheckbox />
                <CustomDetailsTextArea />
                <RequestDetailsValidationError />
                <div className="buttons mt-5">
                  <button
                    type="submit"
                    className={classNames("button is-primary", { "is-loading": busy })}
                    data-testid={TEST_ID_SPECIAL_SUBMIT}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    disabled={busy}
                    className="button is-light"
                    onClick={handleClose}
                    data-testid={TEST_ID_SPECIAL_CANCEL}
                  >
                    Cancel
                  </button>
                </div>
                {!!error && <p className="notification is-danger">{error}</p>}
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestSpecialDialog;
